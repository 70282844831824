<template>
    <div>
        <v-row>
            <creat-student-meal></creat-student-meal>
        </v-row>
    </div>
</template>
<script>
import creatStudentMeal from './createStudentMeal.vue'
export default {
    components: {
        creatStudentMeal
    }
}
</script>
