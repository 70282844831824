<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          style="margin-top: 30px"
          color="green"
          dark
          v-bind="attrs"
          v-on="on"
        >
          {{ $t("add meal") }}
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ $t("add meal") }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="data.registration_record_id"
                  type="number"
                  :label="$t('register record')"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="data.meal_id"
                  :label="$t('Meal ID')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <input
                  style="
                    width: 272.22px;
                    height: 40p;
                    border: 1px solid #999;
                    border-radius: 4px;
                    padding: 9px;
                    text-align: center;
                  "
                  type="date"
                  v-model="data.date"
                />
              </v-col>
              <v-col cols="12" sm="6" md="6"
                ><v-text-field
                  v-model="data.quantity"
                  type="number"
                  :label="$t('quantity')"
                  required
                ></v-text-field
              ></v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="setData">
            <span v-if="!meal.mealStudentCreateState.loading">
              {{ $t("storage") }}</span
            >
            <v-progress-circular
              v-else
              :width="3"
              color="blue"
              indeterminate
            ></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { meal } from "../../../store/auth";
export default {
  data: () => ({
    meal,
    menu: false,
    data: {
      registration_record_id: null,
      meal_id: null,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      quantity: null,
    },
  }),
  methods: {
    setData() {
      let data = this.data;
      meal.createStudentMeal(data);
      this.data = {};
    },
  },
  computed: {
    dialog() {
      if (meal.mealStudentCreateState.succes) {
        return tue;
      } else {
        return false;
      }
    },
  },
};
</script>